export const IS_MCDAN_ENV = process.env.REACT_APP_ENV === "mcdan";
export const IS_DEV_ENV = process.env.REACT_APP_ENV === "dev";
export const IS_INTERNAL_ENV = IS_MCDAN_ENV || IS_DEV_ENV;
export const SAM_SERVICE_URI = process.env.REACT_APP_SAM_CHART_SERVICE;

export const GEO_MAP_SERVICE_ADDRESS = process.env.REACT_APP_GEO_MAP_SERVICE;
export const MAP_SERVICE_ADDRESS = process.env.REACT_APP_MAP_SERVICE;
export const DATA_SERVICE_ADDRESS = process.env.REACT_APP_DATA_ROOT;
export const VIS_SERVICE_ADDRESS = process.env.REACT_APP_VIS_SERVICE;

export const EMPTY_FILTER_ID = "empty-filter-id";

export const WELL_CURRENT_FILTER_ID_LOCAL_STORAGE_KEY = "current-well-filter-id";
export const FACILITY_CURRENT_FILTER_ID_LOCAL_STORAGE_KEY = "current-facility-filter-id";
