import { useDispatch, useSelector } from "react-redux";

import { setFilteredForecastFolders } from "store/features/arps/arpsSlice";
import { RootState } from "store/rootReducer";

import { UserArpsItem } from "models/UserArpsModel";

import "../context/reducer/arpsReducer";

export default function useTypeWellFolderUpdater() {
  const dispatch = useDispatch();

  // Arps Selectors
  const filteredForecastFolders = useSelector(
    (state: RootState) => state.arps.filteredTypeWellFolders
  );

  function updateTypeWellDataWithParentNode(node: UserArpsItem) {
    // here
    const folders = JSON.parse(JSON.stringify(filteredForecastFolders));
    folders.forEach((folder) => (folder.isEdit = false));
    folders.forEach((folder) =>
      folder.children.forEach((child) => (child.isEdit = false))
    );
    const folderIdx = folders.findIndex(
      (item) =>
        item.folderId === node.folderId && item.reserveCategory === node.reserveCategory
    );

    if (folderIdx < 0 || !folders) {
      return;
    }

    const twFolder = JSON.parse(JSON.stringify(folders[folderIdx]));
    const changedTwIndex = twFolder.children.findIndex((item) => item.id === node.id);
    if (changedTwIndex < 0) {
      return;
    }

    twFolder.children.splice(changedTwIndex, 1, node);
    //update the folder
    const updatedFolders = [...folders];
    updatedFolders.splice(folderIdx, 1, {
      ...twFolder
    });

    dispatch(
      setFilteredForecastFolders({
        type: "TYPEWELLS",
        filteredForecastFolders: updatedFolders
      })
    );
  }

  return { updateTypeWellDataWithParentNode };
}
