import * as portals from "react-reverse-portal";

import { IS_INTERNAL_ENV } from "constants/app.constants";

import { DashboardCapabilities, GridWidgetTemplate } from "models/dashboard";

export const TypeWellEditorWidgetKey: string = "typewell-editor-widget";
export const TypeWellDetailsWidgetKey: string = "typewell-details-widget";

export const GeoMapLoaderWidgetKey: string = "geo-map";
export const MidstreamChartWidgetKey: string = "midstream-chart";
export const MidstreamDataTableWidgetKey = "midstream-data-table";
export const DataTableWidgetKey = "data-table";
export const WellDataAnalyzer = "well-data-analyzer";
export const WidgetTypes: GridWidgetTemplate[] = [
  {
    component: "map",
    maxExpandW: 6,
    componentState: {},
    componentOptions: {},
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || !capabilities.hasMapWidget;
    }
  },
  {
    component: "chart",
    maxExpandW: 6,
    maxExpandH: 6,
    componentState: {
      chartTypeParam: {
        x: {
          property: "Dates.Vintage_Year",
          title: "Vintage",
          dataType: "Integer",
          canBin: true
        },
        y: {
          property: "Well_Design.HzLength_m",
          title: "Hz Length",
          dataType: "Number",
          canBin: true
        }
      },
      chartType: "Rate Cum",
      chartTypeId: 1,
      product: "Oil + Cond",
      forecast: true,
      typewells: true,
      showToolbar: true,
      enableZoom: true,
      average: false,
      producing: false,
      showActiveWellOnly: true,
      daily: false,
      legend: false,
      snapshot: false,
      sum: false,
      cutoff: 50,
      survivorBias: true,
      useWeightedRatioAverage: true,
      useNormalizeBy: false,
      normalizeBy: {
        field: "Well_Design.HzLength_m",
        display: "Hz Length Estimate",
        per: 100,
        unit: "m",
        useMultilinearNormalization: false,
        threshold: 0,
        lowerScalar: 1,
        higherScalar: 1
      },
      log: {
        x: false,
        y: false
      },
      timeStep: "month",
      showWellCount: true
    },
    componentOptions: {
      portalNode: portals.createHtmlPortalNode()
    }
  },
  {
    component: MidstreamChartWidgetKey,
    maxExpandW: 6,
    maxExpandH: 6,
    componentState: {
      chartTypeParam: {
        x: {
          property: "Dates.Vintage_Year",
          title: "Vintage",
          dataType: "Integer",
          canBin: true
        },
        y: {
          property: "Well_Design.HzLength_m",
          title: "Hz Length",
          dataType: "Number",
          canBin: true
        }
      },
      chartType: "Rate Date",
      chartTypeId: 1,
      product: "Gas Inlet",
      forecast: true,
      typewells: true,
      showToolbar: true,
      enableZoom: true,
      average: false,
      producing: false,
      showActiveWellOnly: true,
      daily: false,
      legend: false,
      snapshot: false,
      sum: false,
      cutoff: 50,
      survivorBias: true,
      useWeightedRatioAverage: true,
      useNormalizeBy: false,
      normalizeBy: {
        field: "Well_Design.HzLength_m",
        display: "Hz Length Estimate",
        per: 100,
        unit: "m",
        useMultilinearNormalization: false,
        threshold: 0,
        lowerScalar: 1,
        higherScalar: 1
      },
      log: {
        x: false,
        y: false
      },
      timeStep: "month",
      showWellCount: true
    },
    componentOptions: {
      portalNode: portals.createHtmlPortalNode()
    },
    available: (_?: DashboardCapabilities, hasFeature?): boolean => {
      return hasFeature && hasFeature("Facility");
    }
  },
  {
    component: "data-table",
    componentState: {},
    componentOptions: {}
  },
  {
    component: MidstreamDataTableWidgetKey,
    componentState: {},
    componentOptions: {},
    available: (_?: DashboardCapabilities, hasFeature?): boolean => {
      return hasFeature && hasFeature("Facility");
    }
  },
  {
    component: "xda",
    maxExpandW: 6,
    maxExpandH: 6,
    componentState: {},
    componentOptions: {},
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || capabilities.hasMapWidget;
    }
  },
  {
    component: "threed",
    maxExpandW: 6,
    maxExpandH: 6,
    componentState: {},
    componentOptions: {},
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || capabilities.hasMapWidget;
    }
  },
  {
    component: "well-card",
    maxExpandW: 6,
    maxExpandH: 6,
    componentState: {},
    componentOptions: {},
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || capabilities.hasMapWidget;
    }
  },
  {
    component: "facility-ticket",
    maxExpandW: 6,
    maxExpandH: 6,
    componentState: {},
    componentOptions: {},
    available: (_?: DashboardCapabilities, hasFeature?): boolean => {
      return hasFeature && hasFeature("Facility");
    }
  },
  {
    component: "info",
    maxExpandW: 1,
    maxExpandH: 1,
    componentState: {
      AggregateField: "Header.EntityName",
      IsForecastToggleOn: false,
      SelectedForecastFolder: ""
    },
    componentOptions: {},
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || capabilities.hasMapWidget;
    }
  },
  {
    component: "survey-vis",
    maxExpandW: 6,
    maxExpandH: 6,
    componentState: {},
    componentOptions: {},
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || capabilities.hasMapWidget;
    }
  },
  {
    component: "completion",
    maxExpandW: 6,
    maxExpandH: 6,
    componentState: {},
    componentOptions: {},
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || capabilities.hasMapWidget;
    }
  },
  {
    component: "sam",
    maxExpandW: 12,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {},
    available: (): boolean => IS_INTERNAL_ENV
  },
  {
    component: "sam-table",
    maxExpandW: 12,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {},
    available: (): boolean => IS_INTERNAL_ENV
  },
  {
    component: "sam-rf-pv",
    maxExpandW: 12,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {},
    available: (): boolean => IS_INTERNAL_ENV
  },
  {
    component: "multiphase-chart",
    maxExpandW: 12,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {}
  },
  {
    component: "exploitable-report",
    maxExpandW: 12,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {},
    available: (): boolean => IS_INTERNAL_ENV
  },
  {
    component: TypeWellEditorWidgetKey,
    maxExpandW: 12,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {
      portalNode: portals.createHtmlPortalNode()
    },
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || !capabilities.hasTypeWellEditorWidget;
    }
  },
  {
    component: TypeWellDetailsWidgetKey,
    maxExpandW: 12,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {
      portalNode: portals.createHtmlPortalNode()
    },
    available: (capabilities?: DashboardCapabilities): boolean => {
      return !capabilities || !capabilities.hasTypeWellDetailsWidget;
    }
  },
  {
    component: "geo-map",
    maxExpandW: 12,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {},
    available: (_capabilities?: DashboardCapabilities, hasFeature?): boolean => {
      return hasFeature && hasFeature("Geo Model Loader");
    }
  },
  {
    component: WellDataAnalyzer,
    maxExpandW: 6,
    maxExpandH: 12,
    componentState: {},
    componentOptions: {},
    available: (_capabilities?: DashboardCapabilities, hasFeature?): boolean => {
      return hasFeature && hasFeature("App Tools");
    }
  }
];
