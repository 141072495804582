// eslint-disable-next-line import/no-named-as-default
import { useProjectContext } from "components/project/projects/context";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";
import { useDeleteProjectShapefileMutation } from "components/project/shapefiles/mutations";

import { ShapefileActions } from "./ShapefileActions";

interface IProjectShapefileActionsProps {
  setIsRenaming?: () => void;
  shapefileId: string;
  viewShapefileDetails?: () => void;
}

/**
 * Some actions and properties have been left for dev to implement based on use case, since the properties are different between
 * the layer tree layers, and layer list layers.
 * @param params
 * @returns
 */
export function ProjectShapefileActions(
  params: IProjectShapefileActionsProps
): JSX.Element {
  const { selectedProjectId } = useProjectContext();

  const projectPermissions = useSelectedProjectPermissions();

  const deleteProjectShapefileMutation = useDeleteProjectShapefileMutation({
    projectId: selectedProjectId
  });

  function deleteShapefile() {
    deleteProjectShapefileMutation.mutate({ shapefileId: params.shapefileId });
  }

  return (
    <ShapefileActions
      isDisabled={!projectPermissions.canEditShapefiles}
      deleteShapefile={deleteShapefile}
      {...params}
    />
  );
}
