import { useQuery } from "react-query";

import { getProjectShapefiles } from "api/project";

export const PROJECT_SHAPEFILES_QUERY_KEYS = {
  currentProjectShapefiles: "currentProjectShapefiles"
};

export type PROJECT_SHAPEFILES_QUERY_KEYST = keyof typeof PROJECT_SHAPEFILES_QUERY_KEYS;
export type UseProjectShapefilesQueryParamsT = {
  projectId: string;
  onSuccess?: (data) => void;
  onError?: (error) => void;
  select?;
  queryKey?: PROJECT_SHAPEFILES_QUERY_KEYST;
};

export type UseProjectShapeFilesQueryReturnT = {
  error;
  isError: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  refetch: () => void;
  data;
};

const fetchProjectShapefiles = async ({ queryKey }) => {
  const [, projectId] = queryKey;
  return await getProjectShapefiles(projectId);
};

export function useProjectShapefilesQuery({
  projectId,
  onSuccess,
  onError,
  select,
  queryKey = PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles as PROJECT_SHAPEFILES_QUERY_KEYST
}: UseProjectShapefilesQueryParamsT): UseProjectShapeFilesQueryReturnT {
  const queryInfo = useQuery({
    queryKey: [queryKey, projectId],
    queryFn: fetchProjectShapefiles,
    select,
    onError: (error) => {
      onError && onError(error);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data);
    },
    // Enable automatic refreshing after projectId has been defined, it will not call query on first render
    enabled: projectId !== undefined
  });

  return {
    error: queryInfo.error,
    isError: queryInfo.status === "error",
    isIdle: queryInfo.status === "idle",
    isLoading: queryInfo.status === "loading",
    isSuccess: queryInfo.status === "success",
    refetch: queryInfo.refetch,
    data: queryInfo.data?.data
  };
}
