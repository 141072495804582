import { useEffect, useState } from "react";

import {
  COMPLETION_FRAC_LAYER,
  COMPLETION_PERF_LAYER,
  FACILITY_LABEL_LAYER,
  FACILITY_LAYER,
  NTS_BLOCK_LABEL_LAYER,
  SELECTED_WELL_LAYER,
  SHAPEFILE_LABELS_LAYER,
  WELL_LABEL_LAYER,
  WELL_LAYER,
  XDA_INTERCEPT_LAYER,
  XDA_LABEL,
  XDA_WELL_INTERCEPT_POINT
} from "constants/mapLayers.constants";

import { RasterAttributes } from "models/model";
import { IShapefile, IShapefileNode } from "models/projects";

import { useProjectContext } from "components/project/projects/context";
import { useProjectShapefilesQuery } from "components/project/shapefiles/queries";

import useBetaFeatures from "../../../../../hooks/useBetaFeatures";

export interface ProjectLayerTreeT {
  tree: ProjectTreeNodeT[];
  isLoading: boolean;
  error: string;
}

export interface ProjectTreeNodeT {
  type: "rootFolder" | "layer" | "folder";
  key: string;
  title: string;
  checked: boolean;
  folder?: ProjectShapefileFolderT;
  layer?: ProjectLayerT;
  children?: ProjectTreeNodeT[];
  source?: string;
  className?: string;
  shapefileId?: string;
  shapefileNodeId?: string;
  projectShapefileId?: string;
  projectId?: string;
  name?: string;
  properties?: string[];
  originalIndex?: number;
  fileType?: string;
  rasterAttributes?: RasterAttributes;
}

export interface ProjectShapefileFolderT {
  source: "map" | "project" | "org" | "mcdan";
}

export interface ProjectLayerT {
  name: string;
  type: "map" | "projectShapefile" | "orgShapefile" | "mcdanShapefile";
  selected: boolean;
  style?: ProjectLayerStyleT;
  zoom: [number, number];
  shapefileId?: string;
}

export interface ProjectLayerStyleT {
  color: string;
  strokeColor: string;
  thickness: string | number;
  opacity: number;
}

export const projectTreeRootMap = {
  "Well Layers": [
    WELL_LABEL_LAYER,
    WELL_LAYER,
    SELECTED_WELL_LAYER,
    // HIGHLIGHTED_WELL_LAYER,
    XDA_INTERCEPT_LAYER,
    XDA_LABEL,
    XDA_WELL_INTERCEPT_POINT
  ],
  "Facility Layers": [FACILITY_LAYER, FACILITY_LABEL_LAYER],
  "Completion Layers": [COMPLETION_FRAC_LAYER, COMPLETION_PERF_LAYER],
  "Grid Layers": [
    "Section",
    "Township",
    "Section Label",
    NTS_BLOCK_LABEL_LAYER
    // "Township Label"
  ],
  "Base Layers": ["Base Well"],
  [SHAPEFILE_LABELS_LAYER]: [],
  "Project Shapefiles": [],
  "Organization Shapefiles": [],
  "McDaniel Shapefiles": []
};

export function useProjectLayerTree(): ProjectLayerTreeT {
  // store values

  // context
  const { selectedProjectId } = useProjectContext();

  // hooks
  const projectShapefiles = useProjectShapefilesQuery({
    projectId: selectedProjectId
  });

  const { hasFeature } = useBetaFeatures();

  // state
  const [tree, setTree] = useState<ProjectTreeNodeT[]>([]);

  const parseShapefileChildren = (
    children: IShapefileNode[],
    source: "project" | "org" | "mcdan"
  ): ProjectTreeNodeT[] => {
    const subTree: ProjectTreeNodeT[] = [];
    if (children) {
      children.forEach((node: IShapefileNode | IShapefile, i) => {
        const type = node.type === "node" ? "folder" : "layer";
        const treeNode: ProjectTreeNodeT = {
          type: type,
          originalIndex: i,
          key:
            node.type === "node"
              ? node.shapefileNodeId
              : node.projectShapefileId
              ? node.projectShapefileId
              : node.shapefileId,
          title: node.title,
          checked: false,
          children: [],
          source: source,
          fileType: "shapefile",
          className: node.children ? "shapefile-folder" : "shapefile-leaf"
        };

        if (treeNode.type === "folder") {
          if (source !== "org" && node.shapefileNodeId === "orgLevelShapefiles") return;

          treeNode.folder = {
            source: source
          };
          treeNode.shapefileNodeId = node.shapefileNodeId;
        } else if (treeNode.type === "layer") {
          const shapefile = node as IShapefile;
          const type =
            source === "org"
              ? "orgShapefile"
              : source === "mcdan"
              ? "mcdanShapefile"
              : "projectShapefile";
          if (shapefile) {
            const sfnode = node as IShapefile;
            treeNode.layer = {
              name: node.title,
              type: type,
              selected: true,
              zoom: [0, 22],
              style: {
                color: shapefile.color,
                strokeColor: shapefile.strokeColor,
                thickness: shapefile.thickness,
                opacity: shapefile.opacity
              }
            };
            treeNode.projectId = node.projectId;
            treeNode.shapefileId = node.shapefileId;
            treeNode.projectShapefileId = node.projectShapefileId;
            treeNode.shapefileNodeId = node.shapefileNodeId;
            treeNode.checked = treeNode.layer.selected;
            treeNode.name = node.name;
            treeNode.properties = sfnode.properties;
            treeNode.fileType = sfnode.fileType;
            treeNode.rasterAttributes = sfnode.rasterAttributes;
          }
        }

        if (node.children) {
          treeNode.children = parseShapefileChildren(node.children, source);
        }
        subTree.push(treeNode);
      });
    }

    return subTree;
  };

  useEffect(() => {
    const tree: ProjectTreeNodeT[] = [];
    Object.keys(projectTreeRootMap).forEach((nodeKey) => {
      if (!hasFeature("Facility") && nodeKey === "Facility Layers") return;
      let source: "map" | "project" | "org" | "mcdan" = "map";
      switch (nodeKey) {
        case "Project Shapefiles":
          source = "project";
          break;
        case "Organization Shapefiles":
          source = "org";
          break;
        case "McDaniel Shapefiles":
          source = "mcdan";
          break;
      }

      tree.push({
        type: "rootFolder",
        key: nodeKey,
        title: `${nodeKey}`,
        checked: false,
        source: source,
        children: projectTreeRootMap[nodeKey].map((mapKey): ProjectTreeNodeT => {
          return {
            type: "layer",
            key: mapKey,
            title: mapKey,
            checked: true,
            layer: {
              name: mapKey,
              type: "map",
              selected: true,
              zoom: [0, 22]
            }
          };
        })
      });
    });

    if (projectShapefiles.data?.Project) {
      tree.filter((x) => x.key === "Project Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.Project.children, "project");

      tree.filter((x) => x.key === "Organization Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.Organization.children, "org");

      tree.filter((x) => x.key === "McDaniel Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.McDaniel.children, "mcdan");
    }

    setTree(tree);
  }, [projectShapefiles.data]);

  return {
    tree,
    isLoading: projectShapefiles.isLoading,
    error: null
  };
}
