import { gql } from "@apollo/client";

export const TYPE_WELL_NAMES = gql`
  query typeWells($req: ForecastQueryInput!) {
    typeWells(req: $req) {
      name
      uniqueID
      id
      wellData
    }
  }
`;
export const GET_CHANGE_RECORDS = gql`
  query changeRecords($forecastId: String!) {
    changeRecords(forecastId: $forecastId) {
      changeId
      date
      forecastId
      changedBy
      changes {
        field
        from
        to
      }
      comment
    }
  }
`;

export const GET_WELL_LIST_RECORDS = gql`
  query wellListRecords($forecastId: String!) {
    wellListRecords(forecastId: $forecastId) {
      changeId
      date
      changedBy
      date
      forecastId
      wellList
    }
  }
`;

export const FOLDERS = gql`
  query folders($req: FolderQueryInput!) {
    folders(req: $req) {
      folderId
      name
      owner
      forecasts {
        id
        uniqueID
        reserveCategory
        name
        arps {
          product
          segmentIndex
          startDate
          endDate
          qi
          di
          b
          n
          qf
          trendCum
          df
          maxRate
          slopeType
        }
        constants {
          uniqueId
          product
          value
          unit
        }
        wellData
        order
        source
      }
      projectId
    }
  }
`;

export const UPDATE_FOLDER_NAME = gql`
  mutation ($input: FolderInput!) {
    updateFolderName(input: $input)
  }
`;

export const SET_TYPE_WELL_DATA = gql`
  mutation ($data: WellDataInput!) {
    setWellData(input: $data)
  }
`;

export const DELETE_TYPE_WELL = gql`
  mutation ($input: DeleteWellInput!) {
    deleteTypeWell(input: $input)
  }
`;

export const DELETE_FORECAST = gql`
  mutation ($input: DeleteForecastInput!) {
    deleteForecast(input: $input)
  }
`;

export const SAVE_FORECAST = gql`
  mutation ($input: SaveForecastInput!) {
    saveForecast(input: $input)
  }
`;

export const NEW_COMMENT = gql`
  mutation ($input: NewCommentInput!) {
    newComment(input: $input)
  }
`;
