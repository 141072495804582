import { lazy, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import classnames from "classnames";
import {
  ALL_CHART_TYPES,
  ALL_PRODUCT_TYPES,
  AXIS_TYPE,
  CHART_EMPTY_ERROR
} from "constants/chart.constants";
import { ARPS_CHART_PRODUCT_KEYS } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components/macro";
import { getProductType, noop, retry, toggleForecastLabels } from "utils";

import { IChartSettings } from "models/chart";
import { EntityKind } from "models/entityKind";

import { ErrorBoundary } from "components/base";
import { useDashboardDispatch } from "components/dashboard/hooks/useDashboardDispatch";
import { Indicator } from "components/ui";
import { useUserSettings } from "components/user/hooks";

import ChartAxis from "./ChartAxis";
import ChartToolbar from "./ChartToolbar";
import "./ChartWrapper.scss";
import Screenshot from "./Screenshot";
import WellCountToggle from "./WellCountToggle";
import { getChart } from "./charts/getChart";
import { AddToFilterButton, ContextMenu, CopyUWIButton } from "./components";
import { DataTableChartTypes } from "./components/data-table";
import {
  updateContextMenuPosition,
  updateIsDefaultLockOn,
  useChartDispatch,
  useChartState
} from "./context";
import { useChartSize, useDefaultChartTypeParam, useEchartEvents } from "./hooks";
import ChartLegend from "./legend/Legend";

const default_axis_input = { type: "", value: null };

export type MinMaxT = {
  xMin?: number;
  xMax?: number;
  yMin?: number;
  yMax?: number;
};

const default_saved_axis: MinMaxT = {
  xMin: null,
  xMax: null,
  yMin: null,
  yMax: null
};

const Chart = lazy(() => retry(() => import("./Chart")));

interface ChartWrapperT {
  id: string;
  className?: string;
  onFullscreen?: (b: boolean) => void;
  type?: "Well" | "Midstream";
}

const getSessionMinMax = (id: string) => {
  const sessionChartSettings = JSON.parse(sessionStorage.getItem(`chart::${id}`));
  return sessionChartSettings?.axisMinMax;
};

// Get lock value for selected product
const getProductLock = (settings: IChartSettings) => {
  const product = Object.values(ALL_PRODUCT_TYPES).find(
    (o) => o.label === settings.product
  );

  return product?.lock;
};

function ChartWrapper({
  id,
  className = "",
  onFullscreen = noop,
  type = "Well"
}: ChartWrapperT) {
  // context and dispatch
  const {
    bounds,
    brushEnd,
    brushEndPosition,
    brushedList,
    contextMenuPosition,
    isDefaultLockOn,
    isFullscreen,
    mouseover,
    response,
    screenshot,
    settings
  } = useChartState();
  const chartDispatch = useChartDispatch();
  const dashboardDispatch = useDashboardDispatch();

  // state from store
  const groupBy = useSelector((state: RootState) => state.groupBy);
  const txnId = useSelector((state: RootState) => state.map.txnId);
  const activeEntities = useSelector((state: RootState) => state.app.activeEntityKinds);

  // state
  const [axisInput, setAxisInput] = useState(default_axis_input);
  const [axisLocked, setAxisLocked] = useState<"" | "x" | "y">(""); // variable used to determine which axis is being locked
  const [axisLog, setAxisLog] = useState(
    settings.log ? settings.log : { x: false, y: false }
  );
  const [axisZoomEnabled, setAxisZoomEnabled] = useState(true);
  const [canShowDataTable, setCanShowDataTable] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [lastSettings, setLastSettings] = useState<string>();
  const [hasToConfirmSize, setHasToConfirmSize] = useState(false);
  const [wellsExceeded, setWellsExceeded] = useState(false);

  // Axis min/max from series data
  const [minMax, setMinMax] = useState({
    x: { min: null, max: null },
    y: { min: null, max: null }
  });

  // Axis lock values
  const [savedAxis, setSavedAxis] = useState(() => {
    // Look for session storage, then settings (from saved dashboard), finally set to default
    const sessionMinMax = getSessionMinMax(id);
    const currentMinMax = sessionMinMax ?? settings?.axisMinMax;
    // setting savedAxis as an empty object removes axis label values and data slider functionality
    // as xMin, xMax, yMin, yMax for axisZoom must be null and not undefined
    return currentMinMax && Object.keys(currentMinMax).length
      ? currentMinMax
      : default_saved_axis;
  });

  const [showDataTable, setShowDataTable] = useState(false);
  const [hasData, setHasData] = useState(true);

  // derived state
  const userSettings = useUserSettings();
  const midstreamEnabled = userSettings?.midstreamSettings?.enabled;
  const xAxisInput =
    axisInput.type === "xMin" || axisInput.type === "xMax" ? axisInput : null;
  const yAxisInput =
    axisInput.type === "yMin" || axisInput.type === "yMax" ? axisInput : null;
  const isCrossPlot = settings.chartType === ALL_CHART_TYPES.CrossPlot.label;
  const isProbit = settings.chartType === ALL_CHART_TYPES.Probit.label;
  const hasBrushedList = Boolean(brushedList?.length);
  const lassoContextMenuAllowed = hasBrushedList;
  const lassoContextMenuVisible = brushEnd && Boolean(brushEndPosition);
  const screenshotContainerId = `screenshot-container-${id}`;
  const showLegend = settings.legend.visible && !screenshot.visible && !showDataTable; // hide when screenshot or raw date table is visible
  const contextMenuVisible = Boolean(contextMenuPosition);
  const isJitterPlot =
    settings.chartType === ALL_CHART_TYPES.BoxPlot.label && settings.showScatter;
  const copyUWIContextMenuAllowed =
    settings.sum || isProbit || isCrossPlot || isJitterPlot;

  // refs
  const uwiRef = useRef(null);
  const wrapperRef = useRef(null);
  const chartInstanceRef = useRef(null);

  // custom hooks
  useEchartEvents();
  useChartSize(wrapperRef);
  const defaultChartTypeParam = useDefaultChartTypeParam();

  useEffect(() => onFullscreen(isFullscreen), [isFullscreen]);

  // sync chart ID with context
  useEffect(() => chartDispatch({ type: "id", payload: id }), [id, chartDispatch]);

  // keep last uwi from store
  useEffect(() => {
    if (!groupBy.hoverLegendItem) return;
    uwiRef.current = groupBy.hoverLegendItem;
  }, [groupBy.hoverLegendItem]);

  // If chart settings has changed, mark the dashboard as modified.
  useEffect(() => {
    const nextSettings = JSON.stringify(settings);
    if (!lastSettings) {
      setLastSettings(nextSettings);
    } else if (nextSettings !== lastSettings) {
      dashboardDispatch({ payload: { hasModifiedWidgets: true } });
      setLastSettings(nextSettings);
    }
  }, [settings, lastSettings, dashboardDispatch]);

  const resetReferenceLines = useCallback(() => {
    const next = {
      ...settings,
      referenceLine: {
        lines: [],
        points: [],
        useChartValues: false
      }
    };
    chartDispatch({ type: "settings", payload: next });
  }, [settings]);

  const onChartTypeChanged = useCallback(
    (chartType) => {
      updateIsDefaultLockOn(chartDispatch, true);
      const axisZoomEnabled = chartType != ALL_CHART_TYPES.Pie.label;
      setAxisZoomEnabled(axisZoomEnabled);
      const chart = getChart(chartType);
      if (chart) {
        setSavedAxis(chart.getDefaultAxis());
      } else {
        setSavedAxis(default_saved_axis);
      }
      setAxisLog({ x: false, y: false });
      if (chartType === ALL_CHART_TYPES.Probit.label) {
        setAxisLog({ x: true, y: false });
      }

      resetReferenceLines();
    },
    [resetReferenceLines]
  );

  const onToggleDataTable = useCallback((visible: boolean) => {
    setShowDataTable(visible);
  }, []);

  // Apply default x/y fields on chart type change, and format labels.
  useEffect(() => {
    if (!defaultChartTypeParam) return;

    const { forecast } = settings;
    const { x, y } = defaultChartTypeParam;

    defaultChartTypeParam.x = toggleForecastLabels(x, forecast);
    defaultChartTypeParam.y = toggleForecastLabels(y, forecast);

    const nextSettings = Object.assign({}, settings, {
      chartTypeParam: defaultChartTypeParam
    });

    chartDispatch({ type: "settings", payload: nextSettings });
  }, [settings.chartType]);

  /**
   * Handle the chart data table initial settings.
   */
  useEffect(() => {
    setShowDataTable(false);

    setCanShowDataTable(DataTableChartTypes.includes(settings.chartType));
    updateContextMenuPosition(chartDispatch, null);
  }, [settings.chartType]);

  useEffect(() => {
    const nextSettings = Object.assign({}, settings, { log: axisLog });
    chartDispatch({ type: "settings", payload: nextSettings });
  }, [axisLog]);

  /**
   * clear corresponding axis-lock when axis-field value changes,
   * for Cross Plot
   */
  useEffect(() => {
    if (!isInitialized) return;

    const isCrossPlot = settings.chartType === ALL_CHART_TYPES.CrossPlot.label;
    if (!isCrossPlot) return;

    setSavedAxis((prev) => ({ ...prev, xMin: null, xMax: null }));
  }, [settings.chartTypeParam.x.property]);

  useEffect(() => {
    if (!isInitialized) return;

    const isCrossPlot = settings.chartType === ALL_CHART_TYPES.CrossPlot.label;
    if (!isCrossPlot) return;

    setSavedAxis((prev) => ({ ...prev, yMin: null, yMax: null }));
  }, [settings.chartTypeParam.y.property]);

  useEffect(() => {
    updateContextMenuPosition(chartDispatch, null);
  }, [settings.chartType, settings.product, settings.sum, screenshot.visible]);

  function clearLock() {
    setAxisLocked(() => "");
    setAxisInput(() => default_axis_input);
    setSavedAxis(() => default_saved_axis);
  }

  const checkSeriesDataYValueGreaterThanMax = (max: number) => {
    if (!max) {
      return false;
    }
    return response?.series.some((data) => {
      return data.y.some((value) => value > max);
    });
  };

  /**
   * set y-axis lock defaults,
   * for "rate" charts.
   */
  useEffect(() => {
    if (!isInitialized) return;

    const lock = getProductLock(settings);
    const isSeriesDataYValueGreaterThanMax = checkSeriesDataYValueGreaterThanMax(lock);
    const sessionMinMax = getSessionMinMax(id);
    const chart = getChart(settings.chartType);

    // on refresh, isDefaultLockOn should be true
    if (isDefaultLockOn) {
      // get default axis values for charts types
      // defined in getChart
      if (chart) {
        //there should be an instance of sessionMinMax
        if (sessionMinMax) {
          setSavedAxis(sessionMinMax);
          return;
        }
        setSavedAxis(chart.getDefaultAxisMinMax());
        return;
      }

      // the block below handles y-axis locking for specific products
      if (!sessionMinMax?.yMax) {
        if (lock && !savedAxis?.yMax) {
          setAxisLocked(() => (isSeriesDataYValueGreaterThanMax ? "y" : ""));
          setAxisInput(() => ({
            type: "",
            value: isSeriesDataYValueGreaterThanMax ? lock : null
          }));
          setSavedAxis((prev) => ({
            ...prev,
            yMax: isSeriesDataYValueGreaterThanMax ? lock : null
          }));
        } else if (!isSeriesDataYValueGreaterThanMax) {
          setAxisLocked("");
          setSavedAxis((prev) => ({
            ...prev,
            yMax: null
          }));
        }
      }
    }
  }, [isInitialized, response, settings.product]);

  // Watch for external changes on chart settings axisMinMax
  // E.g: ProductSelector
  useEffect(() => {
    setSavedAxis(
      settings?.axisMinMax && Object.keys(settings.axisMinMax).length
        ? { ...settings.axisMinMax }
        : default_saved_axis
    );
  }, [settings.axisMinMax]);

  useEffect(() => {
    if (!settings.key) {
      return;
    }
    let product = ARPS_CHART_PRODUCT_KEYS[settings.key];
    if (product === settings.product) {
      return;
    }
    product = getProductType(product);

    const nextSettings = Object.assign({}, settings, { product: product.key });
    chartDispatch({ type: "settings", payload: nextSettings });
  }, [settings, settings.key, settings.product]);

  useEffect(() => {
    if (!(isInitialized && txnId.id && !txnId.id.length)) {
      setIsInitialized(true);
    }
  }, [txnId, isInitialized]);

  useEffect(() => {
    const { forecast, chartTypeParam } = settings;
    const { x, y } = chartTypeParam;

    const xField = toggleForecastLabels(x, forecast);
    const yField = toggleForecastLabels(y, forecast);

    const nextSettings = Object.assign({}, settings, {
      chartTypeParam: {
        x: xField,
        y: yField
      }
    });

    chartDispatch({ type: "settings", payload: nextSettings });
  }, [settings.forecast]);

  const onSaveAxis = (val, axis) => {
    const { xMax, xMin, yMax, yMin } = val;
    const valAsString = Object.fromEntries(
      Object.entries(val).map(([key, value]) => [
        key,
        value !== null ? String(value) : value
      ])
    );

    updateIsDefaultLockOn(chartDispatch, false);

    if (xMax || xMin || yMax || yMin) {
      setSavedAxis((prev) => ({ ...prev, ...valAsString }));
      setAxisLocked(() => axis);
    } else {
      clearLock();
    }
  };

  const resetAxisLock = (val) => {
    updateIsDefaultLockOn(chartDispatch, false);
    setSavedAxis({ ...savedAxis, [val]: null });
  };

  // show NoWellsAvailable only when empty series limit case
  function onChartUpdate(data, minMaxValues, typeWellSeries) {
    if (!data) {
      return;
    }
    const { requestId, series, messageType } = data;
    if (
      (series.length === 0 ||
        !series ||
        messageType === CHART_EMPTY_ERROR ||
        !requestId) &&
      (!settings.typewells || typeWellSeries?.length === 0)
    ) {
      setHasData(false);
      return;
    }
    setHasData(true);
    if (!minMaxValues) {
      return;
    }
    setMinMax(minMaxValues);
  }

  const handleMouseEnter = () => () => {
    chartDispatch({ type: "mouseover", payload: true });
  };

  const handleMouseLeave = () => (e) => {
    chartDispatch({ type: "mouseover", payload: false });
    const el = document.elementFromPoint(e.clientX, e.clientY);
    if (el && el.className === "dragcover") {
      return;
    }
  };

  // derived classes
  const axisClassnames = classnames({
    visible: mouseover && !settings.screenshot && !screenshot.visible
  });
  const rootClassnames = classnames("chart-wrapper", className);

  return (
    <ErrorBoundary>
      <Wrapper
        ref={wrapperRef}
        className={rootClassnames}
        data-testid="chartWrapper"
        onMouseEnter={handleMouseEnter()}
        onMouseLeave={handleMouseLeave()}
        screenshot={screenshot.visible}>
        {!hasData && !hasToConfirmSize && !wellsExceeded && (
          <Indicator message={"No Data Available"} />
        )}
        {type === "Midstream" && !midstreamEnabled && (
          <Indicator message={"Midstream is disabled"} />
        )}
        {type === "Midstream" &&
          midstreamEnabled &&
          !activeEntities.includes(EntityKind.Facility) && (
            <Indicator message={"Midstream is hidden"} />
          )}

        <ChartContainer screenshot={screenshot.visible}>
          <Chart
            chartInstanceRef={chartInstanceRef}
            xAxisLog={axisLog.x}
            yAxisLog={axisLog.y}
            setAxisInput={(val) => setAxisInput({ ...val })}
            xAxisZoom={{ xMin: savedAxis.xMin, xMax: savedAxis.xMax }}
            yAxisZoom={{ yMin: savedAxis.yMin, yMax: savedAxis.yMax }}
            axisLocked={axisLocked}
            hasToConfirmSize={hasToConfirmSize}
            wellsExceeded={wellsExceeded}
            setAxisLocked={setAxisLocked}
            setHasToConfirmSize={setHasToConfirmSize}
            setWellsExceeded={setWellsExceeded}
            onUpdate={onChartUpdate}
            showDataTable={showDataTable}
          />
        </ChartContainer>

        <ChartToolbar
          onChartTypeChanged={onChartTypeChanged}
          onToggleDataTable={onToggleDataTable}
          canShowDataTable={canShowDataTable}
          dataTableVisible={showDataTable}
        />

        {showLegend && bounds?.width > 0 && (
          <ChartLegend
            parentDimensions={{ width: bounds.width, height: bounds.height }}
            id={screenshotContainerId + "chart-legend"}
          />
        )}

        {!showDataTable && (
          <>
            <ChartAxis
              type={AXIS_TYPE.x}
              className={axisClassnames}
              isLogScale={axisLog.x}
              onLogChange={(log) => setAxisLog({ x: log, y: axisLog.y })}
              axisInput={axisZoomEnabled && xAxisInput}
              setAxisInput={(val) => setAxisInput({ ...val })}
              onSaveAxis={(val) => onSaveAxis({ ...savedAxis, ...val }, "x")}
              savedAxis={[savedAxis.xMin && "xMin", savedAxis.xMax && "xMax"]}
              resetAxisLock={resetAxisLock}
              axisMinMax={minMax?.x}
            />
            <ChartAxis
              type={AXIS_TYPE.y}
              className={axisClassnames}
              isLogScale={axisLog.y}
              onLogChange={(log) => setAxisLog({ x: axisLog.x, y: log })}
              axisInput={axisZoomEnabled && yAxisInput}
              setAxisInput={(val) => setAxisInput({ ...val })}
              onSaveAxis={(val) => onSaveAxis({ ...savedAxis, ...val }, "y")}
              savedAxis={[savedAxis.yMin && "yMin", savedAxis.yMax && "yMax"]}
              resetAxisLock={resetAxisLock}
              axisMinMax={minMax?.y}
            />
          </>
        )}

        <Screenshot containerId={screenshotContainerId} />

        {!showDataTable && <WellCountToggle type={type} />}

        <ContextMenu
          allow={lassoContextMenuAllowed}
          visible={lassoContextMenuVisible}
          position={brushEndPosition}
          content={<AddToFilterButton />}
        />

        <ContextMenu
          allow={copyUWIContextMenuAllowed}
          visible={contextMenuVisible}
          position={contextMenuPosition}
          content={<CopyUWIButton uwi={uwiRef.current} />}
        />
      </Wrapper>
    </ErrorBoundary>
  );
}

export default ChartWrapper;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 0;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  border-radius: var(--border-radius);
  background-color: ${(props) =>
    props.screenshot ? "rgba(46, 72, 88, 0.24)" : "inherit"};
  box-shadow: rgba(var(--color-shadow-rgb), 0.2) 0 0 1px;
  padding-top: 10px;
  z-index: 0;

  &.hovered {
    box-shadow: rgba(var(--color-shadow-rgb), 0.4) 0 0 1px;
  }

  &.fullscreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--index-fullscreen);
  }
`;

const ChartContainer = styled.div`
  justify-self: ${(props) => (props.screenshot ? "center" : "auto")};

  &:hover {
    z-index: 1;
  }
`;
